body {
  background-color: #26292e;
  overflow-x: hidden;
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}

/* .body {
  min-height: 100vh;
  position: relative;
} */

.flexed {
  display: flex;
}

.centered {
  justify-content: center;
}

/* Navbar */
.navbar {
  height: 4rem;
  width: 100%;
  background-color: #79c000;
}

.header_nav img {
  width: 100%;
  max-height: 2.2rem;
  margin-left: 1.2rem;
}
.row {
  justify-content: space-between;
  align-items: center;
}

.container {
  margin-top: 2rem;
  max-width: 90%;
}

.bg {
  padding-bottom: 40%;
}

.content_form {
  z-index: 11;
}

.submit-modal p {
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.red {
  color: crimson;
  background-color: #ffd2d2;
  border: 1px solid #ff0000;
}

.green {
  color: green;
  background-color: #ffd2d2;
  border: 1px solid #000;
}

.error {
  color: crimson;
  font-size: 0.8rem;
}

.content {
  position: relative;
}

.inp-spn {
  display: flex;
  flex-direction: column;
}

footer {
  position: relative;
  width: 100%;
  bottom: 0;
  margin-top: 5vh;
}

footer .logos {
  justify-content: center;
  background-color: #fff;
  align-items: center;
}

footer img {
  height: 100px;
}

.posicion_3 {
  padding: 30px;
}

.legals {
  color: #a1a4a6;
  display: flex;
  font-size: 0.9rem;
  line-height: 1;
  margin: auto;
  text-align: center;
  width: 40%;
}

.submit-modal,
.submit-modal .modal-header {
  /* text-align: center;
  justify-content: center !important; */
}

.submit-modal .modal-header {
  border-bottom: 0;
}

@media only screen and (max-width: 767px) {
  body {
    height: auto;
  }

  .container {
    margin-top: 0;
    max-width: 100%;
  }

  .row {
    justify-content: center;
    --bs-gutter-x: 0;
  }

  .bg {
    padding-bottom: 75vh;
  }

  .floating-text h1 {
    padding: 0;
    text-align: center;
    /* position: absolute; */
    color: #fff;
    font-size: 4.5vw;
    top: 5vh;
    left: unset;
    transform: unset;
  }

  footer {
    position: relative;
  }

  footer img {
    height: 75px;
  }

  footer .logos {
    flex-direction: column;
    margin-top: 25px;
  }

  .content_form {
    background-color: #343e47aa;
    max-width: 95%;
    padding: 15px 13px;
    position: absolute;
    text-align: center;
    top: 18vh;
  }

  .content_form > p {
    /* width: 80%; */
    padding: 0 25px;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.1rem rgb(84 84 84 / 5%);
    border-color: #aaaaaa11;
  }
  .legals {
    width: 90%;
    text-align: left;
  }

  .posicion_3 {
    padding: 25px;
  }
}

@media (min-width: 1035px) {
  .content_form {
    width: 33.33%;
    padding: 0 5vw;
    /* margin-right: 20px; */
    /* padding-right: 55px; */
  }
  .floating-text h1 {
    font-family: "Roboto Condensed";
    padding: 0 10%;
    position: absolute;
    top: 25.5vh;
    /* bottom: 0; */
    left: 47%;
    right: auto;
    color: #fff;
    line-height: 1.1;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 4.25rem;
  }
}

/* Extrtnal for Laptop Only */
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .content_form > p {
    font-size: 0.8rem;
  }
  .floating-text h1 {
    font-size: 3rem !important;
    top: 25vh;
  }
}
